<template>
  <!-- <Layout> -->
  <section class="profile">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4">
                <div class="text-center pb-1">
                  <img
                    :src="
                      AuthenticatedUser && AuthenticatedUser.avatarUrl
                        ? IMG_URL + AuthenticatedUser.avatarUrl
                        : 'https://www.nicepng.com/png/full/136-1366211_group-of-10-guys-login-user-icon-png.png'
                    "
                    alt="profile"
                    class="img-lg rounded-circle mb-3"
                  />
                </div>
                <div class="row justify-content-between">
                  <h3 class="mt-3 mx-auto mt-lg-0">
                    {{
                      AuthenticatedUser
                        ? AuthenticatedUser.firstname +
                          " " +
                          AuthenticatedUser.lastname
                        : "-"
                    }}
                  </h3>
                  <div class="d-flex col-12 p-0 align-items-center">
                    <p class="my-1 mx-auto" v-if="AuthenticatedUser">
                      {{ AuthenticatedUser.roles[0].slice(5) }}
                    </p>
                    <p v-else class="mx-auto">-</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 d-flex">
                <div class="mt-auto w-100">
                  <div class="py-4">
                    <p class="clearfix">
                      <span class="float-left"> Statut </span>
                      <span class="float-right text-muted">
                        {{
                          AuthenticatedUser && AuthenticatedUser.isActif
                            ? "Active"
                            : "Inactif"
                        }}
 
                      </span>
                    </p>

                    <p class="clearfix">
                      <span class="float-left"> Mail </span>
                      <span class="float-right text-muted">
                        {{
                          AuthenticatedUser && AuthenticatedUser.email
                            ? AuthenticatedUser.email
                            : "-"
                        }}
                      </span>
                    </p>
                    <p class="clearfix">
                      <span class="float-left"> Équipe(s) </span>
                      <span class="float-right text-muted">
                        <!-- {{AuthenticatedUser}} -->
                        {{
                          AuthenticatedUser && AuthenticatedUser.team
                            ? AuthenticatedUser.team.name
                            : "-"
                        }}
                      </span>
                    </p>
                    <p class="clearfix">
                      <span class="float-left"> Ancienneté </span>
                      <span class="float-right text-muted">
                        {{
                          moment(
                            AuthenticatedUser.createdAt,
                            "DD/MM/YYYY"
                          ).from(new Date())
                        }}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="profile-feed"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { IMG_URL } from "@/helpers/services/api";
export default {
  name: "profile",
  data() {
    return {
      IMG_URL,
    };
  },
  created() {},
  computed: {
    ...mapGetters(["AuthenticatedUser"]),
  },
};
</script>